import React, { FC, ReactElement } from 'react';
import { cn } from '@/lib/classNames';
import { Button } from '@/components/ui/Button';
import { typography } from '@/components/ui/typography';
import { HorizontalPositionMode, VerticalPositionMode } from '@/components/ui/InfoPopover/InfoPopover.typedefs';
import styles from './InfoPopover.module.scss';

const verticalPositionModeClasses: Record<VerticalPositionMode, string> = {
  [VerticalPositionMode.Top]: styles.topMode,
  [VerticalPositionMode.Bottom]: styles.bottomMode,
};

const horizontalPositionModeClasses: Record<HorizontalPositionMode, string> = {
  [HorizontalPositionMode.LeftSide]: styles.leftSideMode,
  [HorizontalPositionMode.Center]: styles.centerMode,
  [HorizontalPositionMode.RightSide]: styles.rightSideMode,
};

interface Props {
  verticalPosition?: VerticalPositionMode;
  horizontalPosition?: HorizontalPositionMode;
  buttonAction: () => void;
  buttonText: string;
  title: string;
  text: string;
  className?: string;
  header?: ReactElement;
}

export const InfoPopover: FC<Props> = ({
  verticalPosition = VerticalPositionMode.Bottom,
  horizontalPosition = HorizontalPositionMode.LeftSide,
  buttonAction,
  title,
  buttonText,
  className,
  text,
  header,
}) => (
  <div
    data-qa='streak-popover'
    className={cn(styles.wrapper, className, {
      [verticalPositionModeClasses[verticalPosition]]: true,
      [horizontalPositionModeClasses[horizontalPosition]]: true,
    })}
  >
    {header && (
      <div className={styles.header}>
        {header}
      </div>
    )}

    <h6 className={cn(typography.platformH3, styles.heading)} data-qa='popover-title'>{title}</h6>
    <p className={cn(typography.platformTextSecondary, styles.text, 'mt-4')} data-qa='popover-text'>{text}</p>
    <Button
      className={cn(styles.button, 'mt-16')}
      type="button"
      mode={Button.mode.Primary}
      size={Button.size.Small}
      text={buttonText}
      onClick={buttonAction}
      data-qa='popover-button'
    />
  </div>
);
