import { isModeratorDeprecated } from '@/controllers/user/user.utils/isModeratorDeprecated';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';
import { isAdminDeprecated } from './isAdminDeprecated';

/** @deprecated Use useHasAccess() instead
 * Details here: https://app.clickup.com/24383048/v/dc/q83j8-12520/q83j8-217804
 * */
export const isCorporateUserDeprecated = (
  user: AuthUserFragment | null,
): boolean => {
  if (!user) {
    return false;
  }

  return Boolean(isAdminDeprecated(user) || isModeratorDeprecated(user));
};
