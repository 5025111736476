import React, { ReactElement, ReactNode } from 'react';
import { CopyButton } from '@/components/ui/CopyButton';
import { ButtonMode } from '@/components/ui/Button';
import styles from './Pre.module.scss';

interface Props {
  children: ReactNode & ReactNode[];
}

const getNodeContent = (children: ReactNode & ReactNode[]): string | null => {
  try {
    const firstChild = children[0] as ReactElement;

    return firstChild.props.children[0];
  } catch {
    return null;
  }
};

export const Pre = (props: Props) => {
  const { children } = props;

  const content = getNodeContent(children);

  return (
    <div className={styles.container}>
      <pre className={styles.pre} data-qa='chat-code-block'>
        {children}
      </pre>

      {content && (
        <div className={styles.copyButtonWrapper} data-qa='copy-code-button'>
          <CopyButton
            content={content}
            mode={ButtonMode.TransparentLight}
          />
        </div>
      )}
    </div>
  );
};
