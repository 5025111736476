import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useDayName } from '@/components/platform/Schedule/hooks/useDayName';
import { useLocaleDayAndMonth } from '@/hooks/useLocaleDayAndMonth';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { getCurrentDate, getYesterdayDate } from '@/lib/helpers/time';

export const useLocaleDateTimeString = () => {
  const { subDomain, language } = useSubDomainContext();

  return (
    value: Date | number | string,
    // passing empty object {} as options will return only Date without time
    options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    },
  ): string => {
    const date = new Date(value);
    const locale = `${language}-${subDomain.toUpperCase()}`;

    return date.toLocaleDateString(locale, options);
  };
};

export const useLocaleTimeString = () => {
  const { subDomain, language } = useSubDomainContext();

  return (
    value: Date | number | string,
    options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    },
  ): string => {
    const date = new Date(value);
    const locale = `${language}-${subDomain.toUpperCase()}`;

    return date.toLocaleTimeString(locale, options);
  };
};

export const useLocaleTimeAndDateString = () => {
  const toLocaleTimeString = useLocaleTimeString();
  const toLocaleDateString = useLocaleDateTimeString();

  return (value: Date | number | string) => ({
    time: toLocaleTimeString(value),
    date: toLocaleDateString(value, {}),
  });
};

export const useLocalDayInMonth = (timestamp: number) => {
  const { t } = useTranslation([I18N_CODES.chat]);

  const getDayName = useDayName();
  const getLocaleDayAndMonth = useLocaleDayAndMonth();

  const dateNow = new Date();
  const date = new Date(timestamp);

  const dateNowWithoutTime = new Date(dateNow.toDateString());
  const dateWithoutTime = new Date(date.toDateString());

  if (dateNowWithoutTime.getTime() - dateWithoutTime.getTime() === 0) {
    return t(`${I18N_CODES.chat}:day_of_week.today`);
  }

  if (dateNowWithoutTime.getTime() - dateWithoutTime.getTime() <= 86400000) {
    return t(`${I18N_CODES.chat}:day_of_week.yesterday`);
  }

  const day = getDayName(date.getDay());
  const dayAndMonth = getLocaleDayAndMonth(date.getDate(), date.getMonth());

  const isCurrentYear = date.getFullYear() === dateNow.getFullYear();

  return isCurrentYear
    ? `${day}, ${dayAndMonth}`
    : `${day}, ${dayAndMonth}, ${date.getFullYear()}`;
};

export const useUserLocaleDateTimeString = (date: Date) => {
  const { subDomain, language } = useSubDomainContext();
  const userLocale = `${language}-${subDomain.toUpperCase()}`;

  const { t } = useTranslation([I18N_CODES.common]);

  const today = getCurrentDate();
  const yesterday = getYesterdayDate();

  const timeOptions: Pick<
    Intl.DateTimeFormatOptions,
    'hour' | 'minute'
  > = {
    hour: 'numeric',
    minute: '2-digit',
  };

  const isOtherYear = date.getFullYear() !== today.getFullYear();

  switch (date.toDateString()) {
    case today.toDateString():
      return `${t(`${I18N_CODES.common}:day.today`)} ${date.toLocaleTimeString(userLocale, timeOptions)}`;
    case yesterday.toDateString():
      return `${t(`${I18N_CODES.common}:day.yesterday`)} ${date.toLocaleTimeString(userLocale, timeOptions)}`;
    default:
      return date.toLocaleDateString(
        userLocale,
        {
          ...timeOptions,
          day: 'numeric',
          month: 'short',
          year: isOtherYear
            ? 'numeric'
            : undefined,
        },
      );
  }
};
