import i18Next from '@/middleware/i18n';
import { ROUTES } from '@/controllers/router/router.contants';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useLearningRootForRedirect, useStudyRouteParams } from '@/components/platform/Study/pages/urlTools';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';
import { useLearnQueryParams } from '../../../hooks/useLearnQueryParams';

export function useActiveRoute() {
  const [parentCourseSlug] = useLocalStorage(LOCAL_STORAGE_KEYS.selectedLearnCourseSlug, '');
  const features = useFeatures();
  const isStudyRouteEnabled = features.isEnabled(Features.StudyRoute);
  const learningRoot = useLearningRootForRedirect();

  const router = i18Next.useRouter();

  const {
    courseSlug,
    courseTopicSlug,
  } = useLearnQueryParams();
  const {
    moduleSlug: moduleSlugStudy,
  } = useStudyRouteParams();
  const moduleSlug = moduleSlugStudy || courseSlug;

  const currentPathname = router.asPath;

  const isEnglishPracticePage = currentPathname.includes(
    ROUTES.user.english,
  );

  const isCourseTopicPage = courseTopicSlug
    ? currentPathname.includes(
      courseTopicSlug,
    )
    : false;

  if (isEnglishPracticePage && isCourseTopicPage) {
    return {
      pathname: ROUTES.user.english,
    };
  }

  if (isCourseTopicPage && !isEnglishPracticePage) {
    return isStudyRouteEnabled
      ? renderRoute(allRoutes.study.profession, {
        professionSlug: parentCourseSlug,
        module: moduleSlug,
      })
      : renderRoute(allRoutes.learn, {
        course: parentCourseSlug,
        module: moduleSlug,
      });
  }

  return learningRoot;
}
