import { getFormattedNotificationsCount, getProductFaviconsPaths } from '@/components/common/NotificationsIndications/helpers/utils';
import { FAVICON_SIZE, TITLE_INDICATION } from '@/components/common/NotificationsIndications/constants';
import { TIMER_BLINK_OFF, TIMER_BLINK_ON } from '@/components/platform/TimeTracker/TimeTracker.constants';
import { isBrowser } from '@/lib/helpers/isBrowser';

interface CreateOrUpdateDynamicFaviconProps {
  numberToDisplay: number;
  hasAnyNotifications: boolean;
}

interface CreateCanvasResult {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null;
}

interface DrawCounterProps {
  context: CanvasRenderingContext2D;
  radius: number;
  text: string;
}

export class NotificationsIndicator {
  private timeoutId: number | null | NodeJS.Timer = null;

  updateFavicon({
    numberToDisplay,
    hasAnyNotifications,
  }: CreateOrUpdateDynamicFaviconProps) {
    if (typeof document === 'undefined') {
      return;
    }

    const iconPaths = getProductFaviconsPaths();

    if (!numberToDisplay) {
      this.replaceFavicon(
        hasAnyNotifications
          ? iconPaths.faviconUnreadPath
          : iconPaths.faviconPath,
      );

      return;
    }

    const { canvas, context } = this.createCanvas() ?? {};

    if (!canvas || !context) {
      return;
    }

    this.drawCounter({
      context,
      radius: canvas.width / 2,
      text: getFormattedNotificationsCount(numberToDisplay),
    });

    this.replaceFavicon(canvas);
  }

  private setNewTitleWithDelay(newTitle: string) {
    this.resetTimeoutIfExists();

    this.timeoutId = setTimeout(() => {
      document.title = newTitle;
    }, 0); // a kludge, kind of. Details: stackoverflow.com/questions/779379
  }

  private resetTimeoutIfExists() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  updateTitle(shouldHaveIndication: boolean) {
    if (!isBrowser) {
      return;
    }

    const currentTabTitle = document.title;

    const metaOgTitle = document.querySelector('meta[property="og:title"]');
    const originalTitle = metaOgTitle?.getAttribute('content') ?? (
      document.title
    );

    const isTrackingTime = (
      currentTabTitle.includes(TIMER_BLINK_ON)
      || currentTabTitle.includes(TIMER_BLINK_OFF)
    );

    if (isTrackingTime) {
      return;
    }

    const hasTitleIndication = currentTabTitle.includes(TITLE_INDICATION);

    if (shouldHaveIndication && hasTitleIndication) {
      const newTitle = currentTabTitle.includes(originalTitle)
        ? currentTabTitle
        : `${TITLE_INDICATION} ${originalTitle}`;

      this.setNewTitleWithDelay(newTitle);

      return;
    }

    if (!shouldHaveIndication && hasTitleIndication) {
      const newTitle = currentTabTitle.replace(TITLE_INDICATION, '');

      this.setNewTitleWithDelay(newTitle);

      return;
    }

    const newTitle = shouldHaveIndication
      ? `${TITLE_INDICATION} ${originalTitle}`
      : originalTitle;

    this.setNewTitleWithDelay(newTitle);
  }

  private replaceFavicon(newFavicon: HTMLCanvasElement | string) {
    const newFaviconPath = typeof newFavicon === 'string'
      ? newFavicon
      : newFavicon.toDataURL('image/png');

    const currentFavicon = document.querySelector(
      'link[rel*="shortcut icon"]',
    ) as HTMLLinkElement;

    if (!currentFavicon) {
      return;
    }

    currentFavicon.href = newFaviconPath;
  }

  private createCanvas(): CreateCanvasResult | null {
    const canvas = document.createElement('canvas');

    canvas.width = FAVICON_SIZE;
    canvas.height = FAVICON_SIZE;

    const context = canvas.getContext('2d');

    return { canvas, context };
  }

  private drawCounter({
    context,
    radius,
    text,
  }: DrawCounterProps) {
    /* eslint-disable no-param-reassign */
    context.beginPath();
    context.fillStyle = `rgb(226, 85, 68)`;
    context.arc(radius, radius, radius, 0, 2 * Math.PI, false);
    context.fill();

    context.beginPath();
    context.font = '700 18px Manrope';
    context.fillStyle = 'white';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(text, radius, radius + 1);
    context.fill();
    /* eslint-enable no-param-reassign */
  }
}
