import React, { FC, memo, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';
import { MateMarkdown } from '@/components/platform/Markdown';
import { Editable } from '@/components/ui/Editable';
import { disableDefaultMarkdownListBehavior } from '@/components/platform/Chat/chat.helpers';
import { useGetMentionedParticipantName } from '@/components/platform/Chat/components/ChatMarkdown/hooks/useGetMentionedParticipantName';
import { extractParticipantId } from '@/components/ui/ChatInput/InputField/InputField.utils/extractMentions';
import { CopyAsHTML } from '@/components/platform/Chat/components/CopyAsHTML';
import { useParticipantsContext } from '../../contexts/ParticipantsContext';
import styles from './ChatMarkdown.module.scss';

const DOUBLE_LINE_BREAK_REGEX = /([^\n])\n\n/g;

interface Props {
  text: string;
  shouldHighlightMentions?: boolean;
  withHTML?: boolean;
}

export const ChatMarkdown: FC<Props> = memo(({
  text,
  shouldHighlightMentions = true,
  withHTML = false,
}) => {
  const { participant, onMentionClick } = useParticipantsContext();

  const getMentionedParticipantName = useGetMentionedParticipantName(text);
  const processText = useMemo(() => (
    disableDefaultMarkdownListBehavior(text)
      .replaceAll(
        DOUBLE_LINE_BREAK_REGEX,
        (res) => res.substring(0, 2),
      )
  ), [text]);

  return (
    <CopyAsHTML>
      <Editable>
        <MateMarkdown
          source={processText}
          className={styles.chatMarkdown}
          skipHtml={!withHTML}
          components={{
            a({ children, href }) {
              const participantId = extractParticipantId(href);

              const isMention = Boolean(participantId);
              const isOwnMention = participantId === GroupMentions.All
                || Number(participantId) === participant?.id;
              const highlightMentions = (
                isMention && !isOwnMention && shouldHighlightMentions
              );
              const highlightOwnMentions = (
                isOwnMention && shouldHighlightMentions
              );
              const dataQa = isOwnMention
                ? 'own-mention-link'
                : 'mention-link';

              return (
                <a
                  href={href}
                  target={href?.includes('http')
                    ? '_blank'
                    : '_self'}
                  rel="noreferrer"
                  onClick={onMentionClick(isMention, participantId)}
                  className={cn(
                    { [styles.mention]: highlightMentions },
                    { [styles.ownMention]: highlightOwnMentions },
                  )}
                  data-qa={dataQa}
                >
                  {isMention
                    ? getMentionedParticipantName(participantId)
                    : children}
                </a>
              );
            },
          }}
        />
      </Editable>
    </CopyAsHTML>
  );
});
