import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useRouter } from '@/middleware/i18n';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchableTopicFragment } from '@/components/platform/GlobalSearch/graphql/generated/searchableTopic.fragment.generated';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { IconLearnTheory } from '@/components/ui/icons/IconLearnTheory';
import { IconLock } from '@/components/ui/icons/IconLock';
import { ToolTip, VerticalPositionMode } from '@/components/ui/ToolTip';
import { MateMarkdown } from '@/components/platform/Markdown';
import { typography } from '@/components/ui/typography';
import { Editable } from '@/components/ui/Editable';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import { useTopicUrlForUnsureRedirect } from '@/components/platform/Study/pages/urlTools';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import styles from './SearchableTopicDeprecated.module.scss';

interface Props {
  matchedTopic: GlobalSearchMatchFragment;
}

export const SearchableTopicDeprecated: FC<Props> = memo(({
  matchedTopic,
}) => {
  const router = useRouter();

  const entity = matchedTopic.entity as SearchableTopicFragment;

  const { topic } = entity;

  const topicName = topic?.name;
  const courseName = topic?.course?.nameShort;
  const accessPreferences = topic?.meta.access;

  const theory = useMemo(() => {
    if (!matchedTopic.highlights.body?.length) {
      return null;
    }

    return matchedTopic.highlights.body.join('...');
  }, [matchedTopic.highlights.body]);

  const link = useTopicUrlForUnsureRedirect({
    courseTopicSlug: topic?.slug,
    moduleSlug: topic?.course?.slug,
    section: TopicSections.Theory,
  });

  const onClick = useCallback(() => {
    if (!topic) {
      return;
    }

    if (!link) {
      return;
    }

    GlobalSearchEvents.sendEvent(
      GlobalSearchEvents.events.searchResultClicked,
      { searchableType: SearchableEntityType.Theory },
    );

    router.push(link);
  }, [topic, router, link]);

  if (!topic) {
    return null;
  }

  return (
    <SearchResultWrapper
      onClick={onClick}
      disabled={!accessPreferences?.isGranted}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          {accessPreferences?.isGranted
            ? (
              <IconLearnTheory className={styles.icon} />
            )
            : (
              <ToolTip
                text={accessPreferences?.deniedReason || 'No access'}
                verticalPosition={VerticalPositionMode.Top}
              >
                <IconLock className={styles.iconLock} />
              </ToolTip>
            )}

          <span className={typography.platformH4}>
            {topicName}
          </span>

          <span className={styles.courseName}>
            {` - ${courseName}`}
          </span>
        </header>

        {theory && (
          <main>
            <Editable>
              <MateMarkdown source={theory} />
            </Editable>
          </main>
        )}
      </div>
    </SearchResultWrapper>
  );
});
