import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import {
  useUserStreakUpdatedSubscription,
} from '@/components/platform/Streaks/graphql/generated/userStreakUpdated.subscription.generated';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  BestUserStreakDocument,
  BestUserStreakQuery,
  BestUserStreakQueryVariables,
} from '@/components/platform/Streaks/graphql/generated/bestUserStreak.query.generated';
import {
  ActiveUserStreakDocument,
} from '@/components/platform/Streaks/graphql/generated/activeUserStreak.query.generated';
import { useShowGamificationNotification } from '@/hooks/useShowGamificationNotification';
import { MESSAGE_TYPES, MessageTypes } from '@/lib/constants/messages';

export const useUserStreaksUpdated = () => {
  const { t } = useTranslation([I18N_CODES.common]);
  const [authUser] = useAuthUser({ ssr: true });
  const { showGamificationNotification } = useShowGamificationNotification();

  useUserStreakUpdatedSubscription({
    skip: !isBrowser,
    variables: {
      userId: authUser?.id || 0,
    },
    onData: ({
      client,
      data: { data },
    }) => {
      const updatedStreak = data?.userStreakUpdated;

      if (!updatedStreak) {
        return;
      }

      client.writeQuery({
        query: ActiveUserStreakDocument,
        data: {
          activeUserStreak: {
            ...updatedStreak,
          },
        },
      });

      const cachedBestUserStreak = client.readQuery<
        BestUserStreakQuery,
        BestUserStreakQueryVariables
      >({
        query: BestUserStreakDocument,
      });

      const bestUserStreakDaysCount = cachedBestUserStreak
        ?.bestUserStreak?.activeDaysCount;

      if (
        bestUserStreakDaysCount
        && bestUserStreakDaysCount < updatedStreak.activeDaysCount
      ) {
        client.writeQuery({
          query: BestUserStreakDocument,
          data: {
            bestUserStreak: updatedStreak,
          },
        });
      }

      showGamificationNotification({
        modalOptions: {
          id: updatedStreak.id,
          type: MessageTypes.Streak,
          payload: updatedStreak,
        },
        flashMessageOptions: {
          type: MESSAGE_TYPES.streak,
          heading: t(
            `${I18N_CODES.common}:streak_flash_message_heading`,
            {
              count: updatedStreak.activeDaysCount,
            },
          ),
          text: t(`${I18N_CODES.common}:streak_flash_message_text`),
        },
      });
    },
  });
};
