import React, { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import without from 'lodash/without';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeSlug from 'rehype-slug';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib';
import { IconChevronDown } from '@/components/ui/icons/IconChevronDown';
import { IconChevronRight } from '@/components/ui/icons/IconChevronRight';
import { transformImageUri } from '@/components/platform/Markdown/helpers';
import { MateMarkdownProps } from '@/components/platform/Markdown/Markdown.typedefs';
import { Pre } from '@/components/platform/Markdown/Pre';
import styles from '@/components/platform/Markdown/Markdown.module.scss';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import {
  ALLOWED_MARKDOWN_ELEMENTS,
} from '@/components/platform/Markdown/Markdown.constants';
import {
  useMarkdownTheme,
} from '@/components/platform/Markdown/theme/useMarkdownTheme';

export const MateMarkdown: FC<MateMarkdownProps> = ({
  source,
  className: propsClassName,
  isHeadingsAsLinks,
  allowedElements = ALLOWED_MARKDOWN_ELEMENTS,
  disallowedElements,
  skipHtml,
  onClick,
  components,
  customHighlighterStyles = {},
}) => {
  const rehypePlugins: PluggableList = useMemo(() => {
    if (skipHtml) {
      return [];
    }

    if (isHeadingsAsLinks) {
      return [rehypeRaw, rehypeSlug, [rehypeAutolinkHeadings, { behavior: 'before' }]];
    }

    return [rehypeRaw];
  }, [skipHtml, isHeadingsAsLinks]);

  const [
    hintState,
    setHintState,
  ] = useIsOpenStateDeprecated({ initialState: false });

  const verifiedAllowedElements = useMemo(() => (
    disallowedElements?.length
      ? without(allowedElements, ...disallowedElements)
      : allowedElements
  ), [allowedElements, disallowedElements]);

  const markDownTheme = useMarkdownTheme();

  return (
    <NoSSR>
      <ReactMarkdown
        className={cn(propsClassName, 'markdown')}
        includeElementIndex
        rawSourcePos
        remarkPlugins={[remarkGfm]}
        rehypePlugins={rehypePlugins}
        transformImageUri={transformImageUri}
        allowedElements={verifiedAllowedElements}
        unwrapDisallowed
        components={{
          code(props) {
            const {
              className,
              children,
            } = props;

            const match = /language-(\w+)/.exec(className || '');

            return match
              ? (
                <SyntaxHighlighter
                  language={match[1]}
                  PreTag="div"
                  style={markDownTheme}
                  customStyle={{
                    fontSize: '14px',
                    border: 'none',
                    overflowX: 'auto',
                    ...customHighlighterStyles,
                  }}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              )
              : (
                <code className={className}>
                  {children}
                </code>
              );
          },
          mark: ({ children }) => (
            <mark className={styles.mark}>
              {children}
            </mark>
          ),
          pre: Pre,
          sup({ children }) {
            return (
              <sup className={styles.sup}>
                {children}
              </sup>
            );
          },
          sub({ children }) {
            return (
              <sub className={styles.sub}>
                {children}
              </sub>
            );
          },
          details: ({ children }) => (
            <details
              className={styles.details}
              open={hintState}
              onToggle={setHintState}
            >
              {children}
            </details>
          ),
          a({ children, href }) {
            return (
              <a
                href={href}
                target={href?.includes('http')
                  ? '_blank'
                  : '_self'}
                rel="noreferrer"
                onClick={onClick}
              >
                {children}
              </a>
            );
          },
          summary({ children }) {
            return (
              <summary className={styles.summary}>
                <>
                  <IconChevronDown className={styles.openIcon} />
                  <IconChevronRight className={styles.closeIcon} />
                  {children}
                </>
              </summary>
            );
          },
          ...components,
        }}
      >
        {source}
      </ReactMarkdown>
    </NoSSR>
  );
};
