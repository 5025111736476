import { isBrowser } from '@/lib/helpers/isBrowser';
import {
  MAX_VISIBLE_ACHIEVEMENTS_COUNT,
} from '@/components/platform/Learn/CoursePageContent/components/AchievementsBlock';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import {
  GetLatestUserAchievementsDocument,
  GetLatestUserAchievementsQuery,
} from '@/components/platform/Learn/CoursePageContent/graphql/generated/getLatestUserAchievements.query.generated';
import { useAchievementUnlockedSubscription } from '@/components/platform/Achievements/graphql/generated/achievementUnlocked.subscription.generated';
import { useShowGamificationNotification } from '@/hooks/useShowGamificationNotification';
import { MESSAGE_TYPES, MessageTypes } from '@/lib/constants/messages';

export const useAchievementUnlocked = (): void => {
  const [authUser] = useAuthUser({ ssr: true });
  const { showGamificationNotification } = useShowGamificationNotification();

  useAchievementUnlockedSubscription({
    skip: !isBrowser,
    variables: {
      userId: authUser?.id ?? 0,
    },
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const achievementUnlocked = data?.achievementUnlocked;

      if (!achievementUnlocked) {
        return;
      }

      showGamificationNotification({
        modalOptions: {
          id: achievementUnlocked.id,
          type: MessageTypes.Achievement,
          payload: achievementUnlocked,
        },
        flashMessageOptions: {
          type: MESSAGE_TYPES.achievement,
          heading: achievementUnlocked.achievement.name,
          text: `achievement_unlocked`,
          code: achievementUnlocked.achievement.name,
          data: achievementUnlocked,
        },
      });

      const latestAchievementCache = cache
        .readQuery<GetLatestUserAchievementsQuery>({
          query: GetLatestUserAchievementsDocument,
          variables: {
            userId: authUser?.id ?? 0,
            limit: MAX_VISIBLE_ACHIEVEMENTS_COUNT,
          },
        });

      if (!latestAchievementCache?.getLatestUserAchievements) {
        return;
      }

      const alreadyExist = latestAchievementCache.getLatestUserAchievements
        .some(
          (cachedAchievement) => (
            cachedAchievement.id === achievementUnlocked.id
          ),
        );

      if (alreadyExist) {
        return;
      }

      const updatedLatestUserAchievements = [
        achievementUnlocked,
        ...latestAchievementCache.getLatestUserAchievements,
      ];

      updatedLatestUserAchievements.length = MAX_VISIBLE_ACHIEVEMENTS_COUNT;

      cache.writeQuery<GetLatestUserAchievementsQuery>({
        query: GetLatestUserAchievementsDocument,
        variables: {
          userId: authUser?.id ?? 0,
          limit: MAX_VISIBLE_ACHIEVEMENTS_COUNT,
        },
        data: {
          getLatestUserAchievements: updatedLatestUserAchievements,
        },
      });
    },
  });
};
