import React, { ReactNode } from 'react';
import { DayModifiers } from 'react-day-picker';
import { cn } from '@/lib/classNames';
import { IconFire } from '@/components/ui/icons/IconFire';
import { typography } from '@/components/ui/typography';
import { IconFireFrozen } from '@/components/ui/icons/IconFireFrozen';
import 'react-day-picker/lib/style.css';
import styles from './StreaksCalendarDay.module.scss';

export const StreaksCalendarDay = (
  date: Date,
  modifiers: DayModifiers,
): ReactNode => {
  const dataQa = date.getDay() === new Date().getDay()
    ? 'current-day'
    : 'day';

  return (
    <div
      className={cn(styles.day, {
        [styles.today]: modifiers.today,
        [styles.active]: modifiers.selected,
        [styles.frozen]: modifiers.frozen,
      })}
      data-qa={dataQa}
      data-day-date={date.getDate()}
    >
      <p className={cn(typography.platformTextMain, styles.data)}>
        {date.getDate()}
      </p>

      <div className={styles.iconWrapper}>
        {modifiers.selected && !modifiers.frozen && (
          <IconFire className={styles.iconFire} data-qa="active-streak-day" />
        )}

        {modifiers.frozen && (
          <IconFireFrozen className={styles.iconFrozen} data-qa="frozen-day" />
        )}
      </div>
    </div>
  );
};
