import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useRouter } from '@/middleware/i18n';
import { GlobalSearchMatchFragment } from '@/components/platform/GlobalSearch/graphql/generated/globalSearchMatch.fragment.generated';
import { SearchResultWrapper } from '@/components/platform/GlobalSearch/components/SearchableEntities/SearchResultWrapper';
import { IconLearnTheory } from '@/components/ui/icons/IconLearnTheory';
import { IconLock } from '@/components/ui/icons/IconLock';
import { ToolTip, VerticalPositionMode } from '@/components/ui/ToolTip';
import { MateMarkdown } from '@/components/platform/Markdown';
import { typography } from '@/components/ui/typography';
import { Editable } from '@/components/ui/Editable';
import { GlobalSearchEvents } from '@/components/platform/GlobalSearch/analytics/globalSearch.events';
import { SearchableEntityType } from '@/controllers/graphql/generated';
import {
  SearchableTheoryFragment,
} from '@/components/platform/GlobalSearch/graphql/generated/searchableTheory.fragment.generated';
import { useTopicUrlForUnsureRedirect } from '@/components/platform/Study/pages/urlTools';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';
import styles from './SearchableTheory.module.scss';

interface Props {
  matchedTheory: GlobalSearchMatchFragment;
}

export const SearchableTheory: FC<Props> = memo(({ matchedTheory }) => {
  const router = useRouter();

  const { entity } = matchedTheory;
  const { theory } = entity as SearchableTheoryFragment;
  const courseTopic = theory?.courseTopic;

  const topicName = courseTopic?.name;
  const courseName = courseTopic?.course?.nameShort;
  const accessPreferences = courseTopic?.meta.access;

  const content = useMemo(() => {
    if (!matchedTheory.highlights.body?.length) {
      return null;
    }

    return matchedTheory.highlights.body.join('...');
  }, [matchedTheory.highlights.body]);

  const link = useTopicUrlForUnsureRedirect({
    courseTopicSlug: courseTopic?.slug,
    moduleSlug: courseTopic?.course?.slug,
    section: TopicSections.Theory,
  });

  const onClick = useCallback(() => {
    if (!courseTopic) {
      return;
    }

    if (!link) {
      return;
    }

    GlobalSearchEvents.sendEvent(
      GlobalSearchEvents.events.searchResultClicked,
      { searchableType: SearchableEntityType.Theory },
    );

    router.push(link);
  }, [courseTopic, router, link]);

  if (!courseTopic) {
    return null;
  }

  return (
    <SearchResultWrapper
      onClick={onClick}
      disabled={!accessPreferences?.isGranted}
    >
      <div className={styles.wrapper}>
        <header className={styles.header}>
          {accessPreferences?.isGranted
            ? (
              <IconLearnTheory className={styles.icon} />
            )
            : (
              <ToolTip
                text={accessPreferences?.deniedReason || 'No access'}
                verticalPosition={VerticalPositionMode.Top}
              >
                <IconLock className={styles.iconLock} />
              </ToolTip>
            )}

          <span className={typography.platformH4}>
            {topicName}
          </span>

          <span className={styles.courseName}>
            {` - ${courseName}`}
          </span>
        </header>

        {content && (
          <main>
            <Editable>
              <MateMarkdown source={content} />
            </Editable>
          </main>
        )}
      </div>
    </SearchResultWrapper>
  );
});
