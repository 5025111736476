import React, {
  FC, useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDebounce } from '@/hooks/useDebounce';
import { GlobalSearchSortOption, SearchableEntityType } from '@/controllers/graphql/generated';
import { SearchParams } from '@/components/platform/GlobalSearch/hooks/useSearchByQuery';
import { useGlobalSearchContext } from '@/components/platform/GlobalSearch/GlobalSearchContext';
import { SelectedCategoryTag } from '@/components/platform/GlobalSearch/components/InputSection/SelectedCategoryTag';
import { UserFilter } from '@/components/platform/GlobalSearch/components/InputSection/Filters/UserFilter';
import { ChatFilter } from '@/components/platform/GlobalSearch/components/InputSection/Filters/ChatFilter';
import { SortByButton } from '@/components/platform/GlobalSearch/components/InputSection/SortByButton';
import { Input } from '@/components/platform/GlobalSearch/components/InputSection/Input';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { IconSearch } from '@/components/ui/icons/IconSearch';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './InputSection.module.scss';

interface Props {
  searchQuery: (params: SearchParams) => void;
}

export const DEBOUNCE = 250;

export const InputSection: FC<Props> = ({ searchQuery }) => {
  const { selectedCategory, setSelectedCategory } = useGlobalSearchContext();
  const { t } = useTranslation([I18N_CODES.learn]);
  const { brandName } = useSubDomainContext();

  const [value, setValue] = useState('');
  const [debouncedInputValue] = useDebounce(value, DEBOUNCE);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [
    selectedSortOption,
    setSelectedSortOption,
  ] = useState<GlobalSearchSortOption>(GlobalSearchSortOption.MostRelevant);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const onTagClick = useCallback(() => {
    setSelectedChatId(null);
    setSelectedUserId(null);
    setSelectedSortOption(GlobalSearchSortOption.MostRelevant);

    setSelectedCategory(null);
  }, [setSelectedCategory]);

  const filters = useMemo(() => {
    if (selectedChatId || selectedUserId) {
      return {
        chatId: selectedChatId,
        senderUserId: selectedUserId,
      };
    }

    return undefined;
  }, [selectedChatId, selectedUserId]);

  useEffect(() => {
    if (debouncedInputValue) {
      searchQuery({
        query: debouncedInputValue,
        category: selectedCategory,
        filters,
        sortBy: selectedSortOption,
      });
    }
  }, [
    debouncedInputValue,
    searchQuery,
    selectedCategory,
    filters,
    selectedSortOption,
  ]);

  return (
    <>
      <section className={styles.inputSection}>
        {selectedCategory
          ? (
            <SelectedCategoryTag
              category={selectedCategory}
              onClick={onTagClick}
            />
          )
          : (
            <IconSearch className={styles.searchIcon} />
          )}

        <Input
          value={value}
          onChange={onChange}
          placeholder={t(`${I18N_CODES.learn}:lms-search.input-placeholder-with-product`, { brandName })}
        />
      </section>

      {selectedCategory === SearchableEntityType.ChatMessage && (
        <section className={styles.filtersSection}>
          <div className={styles.filterWrapper}>
            <ChatFilter setChatId={setSelectedChatId} />
          </div>

          <div className={styles.filterWrapper}>
            <UserFilter setUserId={setSelectedUserId} />
          </div>

          <SortByButton
            selectedSortOption={selectedSortOption}
            setSelectedSortOption={setSelectedSortOption}
          />
        </section>
      )}
    </>
  );
};
