import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import i18Next from '@/middleware/i18n';
import { Features } from '@/controllers/features/features.constants';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useIsMobileWidth } from '@/components/platform/TestTasks/hooks/useIsMobileWidth';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { allRoutes, renderRoute } from '@/controllers/router/allRoutes';

export const useShouldRenderMiniBanner = () => {
  const [shouldShowBanner, setShouldShowBanner] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const router = i18Next.useRouter();

  const studyRoot = renderRoute(allRoutes.study);
  const learnRoot = renderRoute(allRoutes.learn, {});
  const studyProfession = `${studyRoot}/[professionSlug]`;
  const isLearn = [
    studyRoot,
    studyProfession,
    learnRoot,
  ].includes(router.route);

  const initStorageFlags = useCallback((storageKey: string, setter: any) => {
    const initValue = localStorage
      .getItem(storageKey);

    if (initValue) {
      setter(JSON.parse(initValue));
    }
  }, []);

  useEffect(() => {
    initStorageFlags(
      LOCAL_STORAGE_KEYS.mobileAppMiniBanner,
      setShouldShowBanner,
    );

    initStorageFlags(LOCAL_STORAGE_KEYS.firstPlatformVisit, setIsFirstVisit);

    const listenForStorage = () => {
      const updatedShowBanner = localStorage
        .getItem(LOCAL_STORAGE_KEYS.mobileAppMiniBanner);

      if (updatedShowBanner) {
        try {
          const parsedBanner = JSON.parse(updatedShowBanner);

          setShouldShowBanner(parsedBanner);
        } catch {
          setShouldShowBanner(true);
        }
      }
    };

    window.addEventListener('storage', listenForStorage);

    return () => {
      window.removeEventListener('storage', listenForStorage);
    };
  }, [shouldShowBanner, initStorageFlags]);

  const features = useFeatures();

  const isMobileAppMiniBannerEnabled = (
    features.isEnabled(Features.MobileAppMiniBanner)
  );

  const isMobileWidth = useIsMobileWidth();

  return (
    isMobileAppMiniBannerEnabled
    && isMobileWidth
    && shouldShowBanner
    && !isFirstVisit
    && isLearn
  );
};
