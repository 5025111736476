import { isBrowser } from '@/lib/helpers/isBrowser';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useNotificationsUpdatedSubscription } from '../graphql/generated/notificationsUpdated.subscription.generated';

export const useNotificationsUpdated = () => {
  const [user] = useAuthUser({ ssr: true });
  const { language } = useSubDomainContext();

  useNotificationsUpdatedSubscription({
    skip: !isBrowser || !user?.id,
    variables: {
      userId: user?.id || 0,
      lang: language,
    },
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const notificationsUpdated = data?.notificationsUpdated;

      if (!notificationsUpdated) {
        return;
      }

      const cachedUserId = cache.identify({
        __typename: 'User',
        id: user?.id,
      });

      cache.modify({
        id: cachedUserId,
        fields: {
          notifications: () => notificationsUpdated,
        },
      });
    },
  });
};
