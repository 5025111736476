import React, { FC } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { cn } from '@/lib';
import { IconFireFrozen } from '@/components/ui/icons/IconFireFrozen';
import { UserStreakBaseFragment } from '@/components/platform/Streaks/graphql/generated/UserStreaks.base.fragment.generated';
import { BestStreak } from '@/components/platform/Streaks/components/BestStreak';
import { IconXPCircle } from '@/components/ui/icons/IconXPCircle';
import styles from './StreaksStats.module.scss';

interface Props {
  activeStreak?: UserStreakBaseFragment | null;
  isActive?: boolean;
}

export const StreaksStats: FC<Props> = ({
  activeStreak,
  isActive = true,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const frozenDaysDataAttribute = `frozen-days-${activeStreak?.frozenDaysCount || 0}`;

  return (
    <div className={styles.container}>
      <div className={styles.statContainer}>
        <div className={styles.statBlock}>
          <div className={styles.xpIcon}>
            <IconXPCircle />
          </div>

          <div className={styles.statText}>
            <span
              className={typography.platformH4}
              data-qa="streak_xp"
            >
              {`+ ${activeStreak?.xp || 0} XP`}
            </span>

            <span
              className={
                cn(typography.platformTextSmall, styles.statHint)
              }
            >
              {t(`${I18N_CODES.common}:streak_xp`)}
            </span>
          </div>
        </div>

        <Divider
          referral={DividerReferrals.Vertical}
          className={styles.divider}
        />

        <div className={styles.statBlock}>
          <div className={styles.frozenFireIcon}>
            <IconFireFrozen />
          </div>

          <div className={styles.statText}>
            <span
              className={typography.platformH4}
              data-qa={frozenDaysDataAttribute}
            >
              {t(
                `${I18N_CODES.common}:frozen_days_count`,
                {
                  count: activeStreak?.frozenDaysCount || 0,
                },
              )}
            </span>

            <span
              className={
                cn(typography.platformTextSmall, styles.statHint)
              }
            >
              {t(`${I18N_CODES.common}:frozen_days`)}
            </span>
          </div>
        </div>
      </div>

      <BestStreak
        isActive={isActive}
      />
    </div>
  );
};
