import React, { FC, memo } from 'react';
import { cn } from '@/lib/classNames';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { typography } from '@/components/ui/typography';
import { useIsMobileWidth } from '@/components/platform/TestTasks/hooks/useIsMobileWidth';
import styles from './ProfileShortInfo.module.scss';

export const ProfileShortInfo: FC = memo(() => {
  const [authUser] = useAuthUser({ ssr: true });
  const isMobile = useIsMobileWidth();

  if (!authUser || isMobile) {
    return null;
  }

  const {
    firstName,
    lastName,
    email,
  } = authUser;

  const username = authUser.firstName
  && authUser.lastName
    ? `${firstName} ${lastName}`
    : email;

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.textWrapper}>
        <h3
          className={cn(
            styles.textItem,
            typography.platformH3,
            'c-text-platform-default',
          )}
        >
          {username}
        </h3>

        {username !== email && (
          <span
            className={cn(
              styles.textItem,
              typography.platformTextSecondary,
              'c-text-platform-tertiary',
            )}
          >
            {email}
          </span>
        )}
      </div>
    </div>
  );
});
