import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DayPicker from 'react-day-picker';
import { WEEKDAYS_LONG, WEEKDAYS_SHORT, MONTHS } from '@/components/ui/FormElements/FormInputs/InputDate/constants';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { StreaksCalendarDay } from '@/components/platform/Streaks/components/StreaksCalendarDay';

import 'react-day-picker/lib/style.css';
import { Loader } from '@/components/ui/Loader';
import { useMonthUserStreaksLazyQuery } from '@/components/platform/Streaks/graphql/generated/monthUserStreaks.query.generated';
import { Button } from '@/components/ui/Button';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { IconArrowRight } from '@/components/ui/icons/IconArrowRight';
import { UserStreakBaseFragment } from '@/components/platform/Streaks/graphql/generated/UserStreaks.base.fragment.generated';
import styles from './StreaksCalendar.module.scss';

type Props = {
  isActive?: boolean;
  activeStreak?: UserStreakBaseFragment | null;
};

export const StreaksCalendar: FC<Props> = memo(({
  isActive = true,
  activeStreak,
}) => {
  const { language } = useSubDomainContext();
  const [updateCalendar, setUpdateCalendar] = useState(false);

  const weekdaysLong = WEEKDAYS_LONG[language] || WEEKDAYS_LONG.en;
  const weekdaysShort = WEEKDAYS_SHORT[language] || WEEKDAYS_SHORT.en;
  const months = MONTHS[language] || MONTHS.en;

  const [
    getMonthUserStreaks, {
      data: monthUserStreaksResult,
      refetch: monthUserStreaksRefetch,
      loading: monthUserStreaksLoading,
    },
  ] = useMonthUserStreaksLazyQuery();

  const streaks = useMemo(
    () => monthUserStreaksResult?.authUser?.monthUserStreaks || [],
    [monthUserStreaksResult],
  );

  const modifiers = useMemo(() => ({
    frozen: streaks
      .flatMap((streak) => streak.usedFrozenDays)
      .map((day) => new Date(day)),
  }), [streaks]);

  const selectedDays = useMemo(() => (streaks.map((streak) => ({
    from: new Date(streak.startedAt),
    to: new Date(streak.lastActiveDay),
  }))), [streaks]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (!monthUserStreaksResult) {
      getMonthUserStreaks();
    }
  }, [
    monthUserStreaksResult,
    getMonthUserStreaks,
    isActive,
  ]);

  const onMonthChange = useCallback(async (monthDay) => {
    setUpdateCalendar(true);

    await monthUserStreaksRefetch?.({
      dateOfMonth: monthDay,
    });

    setUpdateCalendar(false);
  }, [monthUserStreaksRefetch]);

  const isLoading = updateCalendar || monthUserStreaksLoading;

  return (
    <div
      className={styles.streaksCalendar}
      data-qa="streaks-calendar"
    >
      <Loader loading={isLoading} />

      <DayPicker
        key={`${activeStreak?.id}-${activeStreak?.activeDaysCount}`}
        className={styles.calendar}
        selectedDays={selectedDays}
        modifiers={modifiers}
        weekdaysLong={weekdaysLong}
        weekdaysShort={weekdaysShort}
        months={months}
        locale={language}
        firstDayOfWeek={1}
        renderDay={StreaksCalendarDay}
        onMonthChange={onMonthChange}
        navbarElement={({ onNextClick, onPreviousClick, month }) => (
          <div className={styles.navigationContainer}>
            <Button
              mode={Button.mode.TransparentLight}
              data-qa='previous-month-button'
              size={Button.size.Small}
              styling={Button.styling.Gray}
              LeftIcon={IconArrowLeft}
              onClick={() => onPreviousClick()}
            />

            <Button
              mode={Button.mode.TransparentLight}
              size={Button.size.Small}
              styling={Button.styling.Gray}
              LeftIcon={IconArrowRight}
              onClick={() => onNextClick()}
              disabled={
                month.getFullYear() === new Date().getFullYear()
                  && month.getMonth() === new Date().getMonth()
              }
            />
          </div>
        )}
      />
    </div>
  );
});
