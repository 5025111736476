import React, { FC } from 'react';
import styles from '@/components/platform/Streaks/components/StreakFireDay/StreakFireDay.module.scss';
import { IconFire } from '@/components/ui/icons/IconFire';
import { cn } from '@/lib';
import { Selectors } from '@/lib/selectors';
import { IconFireFrozen } from '@/components/ui/icons/IconFireFrozen';

export type FireDayProps = {
  label: string;
  isFrozen: boolean;
  isDisabled: boolean;
};

export const FireDay: FC<FireDayProps> = ({ label, isFrozen, isDisabled }) => (
  <div className={styles.fireDay}>
    {!isFrozen && (
      <IconFire className={cn(styles.fireIcon, {
        [Selectors.Disabled]: isDisabled,
      })}
      />
    )}

    {isFrozen && !isDisabled && (
      <IconFireFrozen className={styles.fireIcon} />
    )}

    <div className={styles.fireTextWrapper}>
      <p className={styles.fireText}>
        {label}
      </p>
    </div>
  </div>
);
