import { MinimumArray } from '@/lib/helpers/utility-types';

export const LS_HAS_SEEN_APPLYING_STREAKS_POPOVER_KEY = 'HAS_SEEN_APPLYING_STREAKS_POPOVER_KEY';

export const SHOW_STREAK_DAYS = 5;
export const ACTIVE_STREAK_FIRE_DAYS_MAX = SHOW_STREAK_DAYS - 1;
export const STREAK_SCREEN_CONGRATS_TRANSLATE_CODES: MinimumArray<3, string> = [
  'streak_reset_tomorrow.warning_message',
  'streak_number_achieved.congrats_message',
  'streak_keep_fire.info_message',
];
