import React, {
  FC,
  useCallback,
  useMemo,
} from 'react';
import { Divider, DividerReferrals } from '@/components/ui/Divider/Divider';
import { IconFire } from '@/components/ui/icons/IconFire';
import { Button } from '@/components/ui/Button';
import { isBrowser } from '@/lib/helpers/isBrowser';
import { cn } from '@/lib';
import { useRouter, useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Dropdown } from '@/components/common/Dropdown';
import { StreaksModalContent } from '@/components/platform/Streaks/components/StreaksModalContent';
import { analyticsSDK } from '@/controllers/analytics';
import { useActiveUserStreakQuery } from '@/components/platform/Streaks/graphql/generated/activeUserStreak.query.generated';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { InfoPopover } from '@/components/ui/InfoPopover';
import {
  LS_HAS_SEEN_APPLYING_STREAKS_POPOVER_KEY,
} from '@/components/platform/Streaks/Streaks.constants';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';
import { StudentStatus } from '@/controllers/graphql/generated';
import { MINIMAL_TABLET_WIDTH } from '@/constants/breakpoints';
import { useWindowSize } from '@/hooks/useWindowSize';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  ChubbsApplying,
} from '@/components/ui/Chubbs/ChubbsApplying/ChubbsApplying';
import { Selectors } from '@/lib/selectors';
import styles from '@/components/platform/Streaks/components/StreaksHeaderWidget/StreaksHeaderWidget.module.scss';
import { useUserStreaksUpdated } from '@/components/platform/Streaks/hooks/useUserStreaksUpdated';

interface Props {
  isMobilePageOpened?: boolean;
}

export const StreaksHeaderWidget: FC<Props> = ({
  isMobilePageOpened,
}) => {
  const { t } = useTranslation([
    I18N_CODES.common,
    I18N_CODES.learn,
    I18N_CODES.vacancies,
  ]);
  const [authUser] = useAuthUser({ ssr: true });

  const router = useRouter();

  const {
    data: activeUserStreakResult,
    loading: loadingActiveUserStreak,
  } = useActiveUserStreakQuery({
    skip: !isBrowser,
  });

  useUserStreaksUpdated();

  const activeStreak = useMemo(
    () => activeUserStreakResult?.activeUserStreak,
    [activeUserStreakResult],
  );

  const lastStreakActiveDays = activeStreak?.activeDaysCount || 0;
  const studentIsOnEmploymentStage = (
    authUser?.studentStatus === StudentStatus.Employment
  );

  const countActiveStreakDay = `number-of-active-streak-days-${lastStreakActiveDays}`;

  const dropdownRenderContent = useCallback(({ isActive }) => (
    <div
      className={cn(
        styles.streaksModal,
        { [Selectors.DropdownOpened]: isActive },
      )}
      data-qa="streaks_modal"
    >
      {isActive && (
        <StreaksModalContent
          activeStreak={activeStreak}
          isActive={isActive}
        />
      )}
    </div>
  ), [activeStreak]);

  const [width] = useWindowSize();

  const sendEvent = useCallback(() => {
    analyticsSDK.streaks.sendWidgetOpenedEvent({});
  }, []);

  const [
    hasSeenPopover,
    setHasSeenPopover,
  ] = useLocalStorage<boolean>(
    LS_HAS_SEEN_APPLYING_STREAKS_POPOVER_KEY,
    false,
  );

  const dropdownRenderTrigger = useCallback((onClick) => {
    const isMobile = width < MINIMAL_TABLET_WIDTH;
    const shouldShowStreakPopover = (
      activeStreak
      && !loadingActiveUserStreak
      && !hasSeenPopover
      && studentIsOnEmploymentStage
    );

    const buttonAction = isMobile
      ? () => router.push(ROUTES.user.streaks)
      : onClick;

    const buttonText = isMobile
      ? String(lastStreakActiveDays)
      : t(`${I18N_CODES.common}:active_days_count`, { count: lastStreakActiveDays });

    return (
      <>
        <Button
          mode={Button.mode.TransparentLight}
          LeftIcon={IconFire}
          onClick={buttonAction}
          className={cn(styles.streaksButton, {
            [styles.pageOpenedState]: isMobilePageOpened,
          })}
          text={buttonText}
          disabled={isMobilePageOpened}
          data-qa={countActiveStreakDay}
        />

        {
          shouldShowStreakPopover && (
            <InfoPopover
              className={styles.popover}
              header={(
                <div className={styles.chubbsWrapper} data-qa='chubbs-wrapper'>
                  <ChubbsApplying
                    width={154}
                    height={112}
                    className={styles.chubbs}
                  />
                </div>
              )}
              title={t(`${I18N_CODES.learn}:vacancyStreak.title`)}
              text={t(`${I18N_CODES.learn}:vacancyStreak.text`)}
              buttonAction={() => setHasSeenPopover(true)}
              buttonText={t(`${I18N_CODES.learn}:streaksPopover.button`)}
            />
          )
        }
      </>
    );
  }, [
    width,
    activeStreak,
    loadingActiveUserStreak,
    hasSeenPopover,
    studentIsOnEmploymentStage,
    lastStreakActiveDays,
    t,
    isMobilePageOpened,
    countActiveStreakDay,
    router,
    setHasSeenPopover,
  ]);

  return (
    <div className={styles.wrapper} data-qa="streaks_block">
      <Dropdown
        isFullWidth
        sendEvent={sendEvent}
        renderTrigger={dropdownRenderTrigger}
        renderContent={dropdownRenderContent}
      />

      <Divider referral={DividerReferrals.Vertical} />
    </div>
  );
};
