export enum HorizontalPositionMode {
  LeftSide = 'leftSideMode',
  RightSide = 'rightSideMode',
  Center = 'centerMode',
}

export enum VerticalPositionMode {
  Top = 'topMode',
  Bottom = 'bottomMode',
}
