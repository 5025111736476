import { GroupMentions } from '@/components/ui/ChatInput/ParticipantMention/ParticipantMention.typedefs';

export type ExtractedMention = {
  participantId: string;
  markup: string;
  raw: string;
};

// mention name in exact format [@__display__]
const CHAT_MENTION_NAME_REGEX = /\[@([^[\]()]*)]/;
// mention participant id in exact format #participant:__id__ or #participant:all
const CHAT_MENTION_PARTICIPANT_REGEX = new RegExp(`#participant:(\\d+|${GroupMentions.All}|${GroupMentions.Student})`);
// mention id in exact format [@__display__](#participant:__id__)
// or [@__display__](#participant:all)
const CHAT_MENTION_REGEX = new RegExp(
  `${CHAT_MENTION_NAME_REGEX.source}\\(${CHAT_MENTION_PARTICIPANT_REGEX.source}\\)`,
  'g',
);

export const extractMentions = (text = ''): ExtractedMention[] => {
  const mentions = text.match(CHAT_MENTION_REGEX);

  return (mentions || []).map((mention) => {
    const idMatchData = mention.match(CHAT_MENTION_PARTICIPANT_REGEX);
    const nameMatchData = mention.match(CHAT_MENTION_NAME_REGEX);

    if (!idMatchData || !nameMatchData) {
      return ({ participantId: '', markup: '', raw: mention });
    }

    const [, participantId] = idMatchData;
    const [, participantName] = nameMatchData;

    return ({
      participantId: participantId || '',
      markup: `@${participantName || ''}`,
      raw: mention,
    });
  });
};

export const processMentions = (text: string): string => (
  text.replace(
    CHAT_MENTION_REGEX,
    (mention) => `@${mention.match(CHAT_MENTION_NAME_REGEX)?.[1] || ''}`,
  )
);

export const extractParticipantId = (mention?: string) => {
  const matchData = mention?.match(CHAT_MENTION_PARTICIPANT_REGEX);

  if (!matchData) {
    return '';
  }

  const [, participantId] = matchData;

  // Student mention is supposed to be replaced with actual student name,
  // otherwise it should not be rendered
  if (participantId === GroupMentions.Student) {
    return '';
  }

  return participantId || '';
};

export const handleTextWithMentionsChange = (
  mentions: ExtractedMention[],
  updatedText: string,
) => {
  let mentionIndex = 0;

  const textWithMentions = mentions.reduce((text, mention) => {
    const mentionStartIndex = text.indexOf(mention.markup, mentionIndex);

    if (mentionStartIndex === -1) {
      return text;
    }

    const mentionEndIndex = mentionStartIndex + mention.markup.length;

    const textWithMention = `${text.substring(0, mentionStartIndex)}${mention.raw}${text.substring(mentionEndIndex)}`;

    mentionIndex = mentionStartIndex + mention.raw.length;

    return textWithMention;
  }, updatedText);

  return textWithMentions;
};
