import { sendAmplitudeData } from '@/controllers/amplitude/amplitude.helpers';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';

export const PlatformEvents = {
  sendEvent: (event: string, data?: Record<string, any>) => {
    const eventName = `platform_${event}`;

    sendAmplitudeData(eventName, data);
    GoogleAnalyticsClient.getInstance().sendEvent(eventName, data);
  },
  events: {
    vacancyCreated: 'vacancy_created',
    vacancyStatusChanged: 'vacancy_status_changed',
    vacancyArchived: 'vacancy_archived',
    vacancyUnarchived: 'vacancy_unarhived',
    vacancyCommentAdded: 'vacancy_comment_added',
    vacancyFavStatusChanged: 'vacancy_fav_status_changed',
    userJobCreated: 'user_job_created',
    userJobUpdated: 'user_job_updated',
    userJobLeaved: 'user_job_leaved',
    userOnboarding: 'user_onboarding',
    inviteButtonClicked: 'invite_button_clicked',
    supportChatButtonClicked: 'support_chat_button_clicked',
    supportChannelButtonClicked: 'support_channel_button_clicked',
    signInLinkSent: 'sign_in_link_sent',
    signInLinkSentAndCopied: 'sign_in_link_sent_and_copied',
    courseUserCreatedAfterReject: 'course_user_created_after_reject',
    setEmailFormSubmitted: 'set_email_form_submitted',
    careerMaterialsLinkOpened: 'career_materials_link_opened',
    careerSupportLinkOpened: 'career_support_link_opened',
    partnerVacanciesOpened: 'partner_vacancies_opened',
    lowJobRatingSubmitted: 'low_job_rating_submitted',
    nextTopicJSIntroductionClicked: 'next_topic_js_introduction_clicked',
    paymentFaqOpened: 'payment_faq_opened',
    paymentSkipOpened: 'payment_skip_opened',
    gitHubAccountWarningShowed: 'github_account_warning_showed',
    gitAccountChangedByLinkInWarning: 'github_account_changed_by_link_in_warning',
    profileLogOutModalOpened: 'profile_log_out_modal_opened',
    profileModalConfirmLogOutClicked: 'profile_modal_confirm_log_out_clicked',
    profileModalRejectLogOutClicked: 'profile_modal_reject_log_out_clicked',
    mateShopVisited: 'mate_shop_visited',
    monthSalaryConfirmed: 'month_salary_confirmed',
    confirmSalaryWindowCancelled: 'confirm_salary_window_cancelled',
    confirmSalaryWindowOpened: 'confirm_salary_window_opened',
    applicationsHistoryClicked: 'applications_history_clicked',
    changeTrackLinkClicked: 'change_track_link_clicked',
    chooseTrackLinkClicked: 'choose_track_link_clicked',
    trackForUnlimStudentsSelected: 'track_for_unlim_students_selected',
    changeTrackModalForUnlimOpened: 'change_track_modal_for_unlim_opened',
    changeTrackModalForUnlimCancelled: 'change_track_modal_for_unlim_cancelled',
    changeTrackModalForUnlimSubmitted: 'change_track_modal_for_unlim_submitted',
    learnPageSectionTabSelected: 'learn_page_section_tab_selected',
    notificationsOpened: 'platform_notifications_opened',
    notificationsRatingSend: 'platform_notifications_rating_send',
    notificationsActionLinkClicked: 'platform_notifications_action_link_clicked',
    leaderboardFaqOpened: 'leaderboard_faq_opened',
    softDeadlineFaqOpened: 'soft_deadline_faq_opened',
  },
};
