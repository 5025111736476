import { HOUR, MINUTE, SECOND } from '@/constants';
import { leadZero } from '../leadZero';

type GenerateTimeArgs = {
  hours: number;
  minutes: number;
  seconds?: number;
  separator?: string;
};

export const getHrsMinsSecsFromMillis = (milliseconds: number): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const hours = Math.floor(milliseconds / HOUR);
  const minutes = Math.floor(milliseconds / MINUTE) % 60;
  const seconds = Math.floor(milliseconds / SECOND) % 60;

  return { hours, minutes, seconds };
};

export const getHrsMinsFromMillis = (milliseconds: number): {
  hours: number;
  minutes: number;
} => {
  const hours = Math.floor(milliseconds / HOUR);
  const minutes = Math.round((milliseconds % HOUR) / MINUTE);

  if (minutes === 60) {
    return {
      hours: hours + 1,
      minutes: 0,
    };
  }

  return { hours, minutes };
};

export const formatTimeUnit = (timeUnit: number): string => {
  const stringifiedTimeUnit = String(timeUnit);

  return stringifiedTimeUnit.length > 1
    ? stringifiedTimeUnit
    : `0${stringifiedTimeUnit}`;
};

export const formatTime = (time: Date): `${string}:${string}` => {
  const minutes = time.getMinutes();
  const hours = time.getHours();

  return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}`;
};

export const generateTimeString = ({
  hours,
  minutes,
  seconds,
  separator = ':',
}: GenerateTimeArgs): string => {
  const formattedElements: string[] = [];

  if (hours) {
    formattedElements
      .push(leadZero(hours, 2));
  }

  [minutes, seconds].forEach((element) => {
    formattedElements
      .push(leadZero(element ?? 0, 2));
  });

  return formattedElements.join(separator);
};

const sundayIndex = 7;

export const getFirstDayOfTheWeek = (
  date: Date = new Date(),
): Date => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const dayIndex = date.getDay() || sundayIndex;
  const delta = dayIndex - 1;

  const prevMonday = new Date(Date.UTC(year, month, day - delta, 0, 0, 0, 0));

  return prevMonday;
};

export const getLastDayOfTheWeek = (date: Date = new Date()): Date => {
  const firstDay = getFirstDayOfTheWeek(date);

  return new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay.getDate() + 6,
    23,
    59,
    59,
  );
};

export const formatDate = (date: number): string => (
  new Date(date).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'long',
  })
);

export const getCurrentDate = (): Date => {
  const currentDate = new Date();

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0,
    0,
  );
};

export const getYesterdayDate = (): Date => {
  const currentDate = new Date();

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1,
    0,
    0,
    0,
    0,
  );
};

export const getTomorrowDate = (): Date => {
  const currentDate = new Date();

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1,
    0,
    0,
    0,
    0,
  );
};

export const formatMillisecondsToISOTime = (
  milliseconds = 0,
): string | null => {
  if (!milliseconds) {
    return null;
  }

  const { hours, minutes, seconds } = getHrsMinsSecsFromMillis(milliseconds);

  return generateTimeString({ hours, minutes, seconds });
};
